import { useEffect, useState, useRef } from 'react';
import {
    Button,
    Row,
    Col,
    message,
} from 'antd';
import Slider from "react-slick";
// import { Helmet } from "react-helmet";
import {
    apiGetSlider,
    apiGetHomeSEO,
} from '../../api/homeSlider';
import WebLayout from '../../components/layout/webLayout';
// import FixedMenu from '../../components/organism/fixedMenu';
// import RouteRedirect from '../../utils/routeRedirect';
import { useWindowSize } from '../../utils/useWindowSize';
import { errorHandling } from '../../utils/errorHandling';
import { RequestAPI } from '../../utils/requestApi';

const Home = (props) => {
    const [getData, setGetData] = useState(true);
    const [colorIcon, setColorIcon] = useState(false);
    const [noMenu, setNoMenu] = useState(false);
    const [silderIndex, setSliderIndex] = useState(0);
    const [sliderData, setSliderData] = useState([]);
    const [seoData, setSEOData] = useState({
        seo_title: '',
        seo_description: '',
    });
    const [dotsDirection, setDotsDirection] = useState('');
    const slider = useRef(null);
    const size = useWindowSize();
    const {
        history,
        seo,
    } = props;

    const slide = (y) => {
        if (slider.current) {
            y > 0 ? (
                slider.current.slickPrev()
            ) : (
                slider.current.slickNext()
            )
        }
    };

    const fetchHomeSEO = async () => {
        try {
            const response = await RequestAPI(apiGetHomeSEO());
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let newSEOData = Object.assign({}, seoData);
                newSEOData.seo_title = seo.seo_title;
                newSEOData.seo_description = seo.seo_desc;
                setSEOData(newSEOData);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
            }
        } catch (error) {
            const errMsg = errorHandling(error);
            if (errMsg) {
                message.error(errMsg);
            }
        }
    };

    const fetchDataSlider = async () => {
        try {
            const response = await RequestAPI(apiGetSlider());
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let worksData = [];
                let aboutData = [];
                let contactData = [];
                resData.map((item) => {
                    if (item.type === "works") {
                        worksData.push(item);
                        return worksData;
                    } else if (item.type === "about") {
                        aboutData.push(item);
                        return aboutData;
                    } else if (item.type === "contact") {
                        contactData.push(item);
                        return contactData;
                    } else {
                        return item;
                    }
                });
                const newSliderData = worksData.concat(aboutData,contactData);
                setSliderData(newSliderData);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
            }
        } catch (error) {
            const errMsg = errorHandling(error);
            if (errMsg) {
                message.error(errMsg);
            }
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (history.location.pathname === "/") {
            let mousewheelevt = (/Firefox/i.test(navigator.userAgent))? "DOMMouseScroll" : "mousewheel";
            
            window.addEventListener(mousewheelevt, (e) => {
                let delta = e.detail? e.detail*(-50) : e.wheelDelta
                slide(delta);
            });
        }

        if (getData && token) {
            fetchDataSlider();
            // fetchHomeSEO();
            setGetData(false);
        }
    // eslint-disable-next-line
    },[getData]);

    let CarouselSettings = {
        dots: size.width <= 768 ? true : noMenu ? false : true,
        fade: false,
        infinite: false,
        speed: 850,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIndex, newIndex) => {
            const carouselLength = sliderData.length;
            setSliderIndex(newIndex);
            setColorIcon(false);
            setNoMenu(false);
            if (newIndex === (carouselLength - 2)) {
                setColorIcon(true);
            } else if (newIndex === carouselLength - 1) {
                setColorIcon(true);
                setNoMenu(true);
            }

            if (oldIndex > newIndex) {
                setDotsDirection('right');
            } else {
                setDotsDirection('left');
            }

            setTimeout(() => {
                setDotsDirection('');
            }, 300);
        },
        appendDots: (dots) => {
            const carouselLength = sliderData.length;
            let activeColor = false;
            let curIndex = 0;
            dots.map((item) => {
                if (item.props.className !== "") {
                    curIndex = item.key;
                    return curIndex;
                };
                return item;
            });
            if ((carouselLength - 2) <= curIndex) {
                activeColor = true;
            }
            return (
                <div>
                    <ul className={(activeColor ? "slick__dots-colors" : "")} style={{ position: 'relative', padding: 0 }}>
                        <div 
                            className={"slick-worm " + (dotsDirection ? "slick-worm--active-go"+dotsDirection+" " : "")} 
                            style={{ left: (silderIndex * 16) + 'px' }} />
                        {
                            dots.map((item, index) => {
                                return (
                                    <li className={item.props.className} key={index}>
                                        {item.props.children}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            );
        }
    };

    return (
        <WebLayout noMenu={size.width <= 768 ? false : noMenu} colorIcon={colorIcon} ctx={props}>
            {/* <Helmet>
                <title>{seoData.seo_title}</title>
                <meta name="description" content={seoData.seo_description} />
                <meta property='og:title' content={seoData.seo_title}/>
                <meta property='og:description' content={seoData.seo_description}/>
            </Helmet> */}
            <Slider ref={slider} className={"home__carousel-wrapper "} {...CarouselSettings}>
                {
                    sliderData.map((item, index) => {
                        if (item.type === "about") {
                            return (
                                <div key={index}>
                                    <div className="home__content" style={{ backgroundColor: '#fff' }}>
                                        <div className="home__carousel home__carousel--about">
                                            <Row align="middle">
                                                <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                                    <img src={item.images} alt="carousel-img-about" />
                                                </Col>
                                                <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                                    <div className="home__carousel-text">
                                                        <div className="home__carousel-title home__carousel-title--big">
                                                            <div style={{ color: '#333333' }}>{item.title_row_1 || "Our Work."}</div>
                                                            <div style={{ color: '#4F4F4F' }}>{item.title_row_2 || "Our Passion."}</div>
                                                            <div style={{ color: '#828282' }}>{item.title_row_3 || "Our People."}</div>
                                                        </div>
                                                        <p className="home__carousel-desc home__carousel-desc--small">
                                                            {item.description}
                                                        </p>
                                                        <a href={process.env.REACT_APP_BASE_URL + "/about"}>
                                                            <Button className="btn btn__transparent btn__transparent--grey">
                                                                {item.button_text || "More About Us"}
                                                            </Button>
                                                        </a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            );
                        // disable contact slide
                        // } else if (item.type === "contact") {
                        //     return (
                        //         <div key={index}>
                        //             <div className="home__content" style={{ backgroundColor: '#fff' }}>
                        //                 <div className="home__carousel">
                        //                     <Row align="middle">
                        //                         <Col span={12} xs={24} sm={24} md={24} lg={12}>
                        //                             <div className="home__contact home__contact-subtitle">
                        //                                 {item.title_row_1 || "Got a project?"}
                        //                             </div>
                        //                             <div className="home__contact home__contact-title">
                        //                                 {item.title_row_2 || "Let’s talk"}
                        //                             </div>
                        //                             <img src={item.images} alt="carousel-img-contact" />
                        //                             <div className="home__contact home__contact-link"  onClick={() => RouteRedirect(props, 'contact', 'right')}>
                        //                                 {item.link_text || "Tell us what you need"}
                        //                             </div>
                        //                         </Col>
                        //                     </Row>
                        //                 </div>
                        //                 <FixedMenu noMenu={noMenu} fixed={true} ctx={props}/>
                        //             </div>
                        //         </div>
                        //     );
                        } else if (item.type !== "contact") {
                            return (
                                <div key={index}>
                                    <div className="home__content" style={{ backgroundColor: item.background_color || "#783CBD" }}>
                                        <div className="home__carousel">
                                            <Row align="middle">
                                                <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                                    <img src={item.images} alt={"carousel-img-"+index} />
                                                </Col>
                                                <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                                    <div className="home__carousel-text">
                                                        <h5 className="home__carousel-title">
                                                            {item.small_title}
                                                        </h5>
                                                        <h1 className="home__carousel-desc">
                                                            {item.big_title}
                                                        </h1>
                                                        <a href={process.env.REACT_APP_BASE_URL + "/works/" + item.work_detail.string_id}>
                                                            <Button className="btn btn__transparent btn__transparent--white">
                                                                {item.button_text || "Read More"}
                                                            </Button>
                                                        </a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })
                }
            </Slider>
        </WebLayout>
    );
};

export default Home;