export const validateEmail = (value) => {
    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))
    {
      return true;
    }
      return false;
};

export const validateNumber = (value) => {
    if (/^[0-9]*$/.test(value)) {
        return true;
    } else {
        return false;
    }
};

export const validatePhoneNumber = (value) => {
    if (/^08[0-9]*$/.test(value)) {
        return true;
    } else {
        return false;
    }
};

export const isEmpty = (obj) => {
  for(let prop in obj) {
    if(obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};