const getBaseUrl = process.env.REACT_APP_API_URL;

export const apiGetBlogsList = (idCategory) => ({
    body: {
        category: idCategory,
    },
    method: 'POST',
    url: `${getBaseUrl}/blogs/get`,
    isAuthorize: true,
});

export const apiGetBlogsCopytext = () => ({
    method: 'POST',
    url: `${getBaseUrl}/blogs/get-blog-copytexts`,
    isAuthorize: true,
});

export const apiGetBlogsCategories = () => ({
    method: 'POST',
    url: `${getBaseUrl}/blogs/get-blog-categories`,
    isAuthorize: true,
});

export const apiGetBlogsDetail = (id) => ({
    body: {
        string_id: id,
    },
    method: 'POST',
    url: `${getBaseUrl}/blogs/detail`,
    isAuthorize: true,
});

export const apiPostBlogsTracker = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/blogs/post-tracker`,
    isAuthorize: true,
});