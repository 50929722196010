import { useEffect, useState, useRef } from 'react';
import {
    Button,
    Row,
    Col,
    Input,
    message,
    Modal,
} from 'antd';
// import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import {
    apiGetContact,
    apiPostContactMsg,
} from '../../api/contact';
import WebLayout from '../../components/layout/webLayout';
import Whatsapp from '../../assets/images/whatsapp-purple.svg';
import RightIcon from '../../assets/images/right.svg';
import { RequestAPI } from '../../utils/requestApi';
import { errorHandling } from '../../utils/errorHandling';
import {
    validateEmail,
    validateNumber,
    validatePhoneNumber,
} from '../../utils/validations';

const { TextArea } = Input;

const Contact = (props) => {
    const recaptchaRef = useRef();
    const [getData, setGetData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dataMsg, setDataMsg] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    const [errorObj, setErrorObj] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });
    const [dataDetail, setDataDetail] = useState('');
    // const {
    //     seo,
    // } = props;

    const fetchContactData = async () => {
        try {
            const response = await RequestAPI(apiGetContact());
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                setDataDetail(resData);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
            }
        } catch (error) {
            const errMsg = errorHandling(error);
            if (errMsg) {
                message.error(errMsg);
            }
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (getData && token) {
            fetchContactData();
            setGetData(false);
        }
        // eslint-disable-next-line
    }, [getData]);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let newDataObj = Object.assign({}, dataMsg);
        let newErrorObj = Object.assign({}, errorObj);

        newDataObj[name] = value;
        newErrorObj[name] = '';

        setDataMsg(newDataObj);
        setErrorObj(newErrorObj);
    };

    const submitMessage = async () => {
        setLoading(true);
        try {
            const token = await recaptchaRef.current.executeAsync();
            let newDataObj = Object.assign({}, dataMsg);
            newDataObj['g-recaptcha-response'] = token;
            const response = await RequestAPI(apiPostContactMsg(newDataObj));
            console.log(response);
            if (response.status === 200 || response.status === 201) {
                setDataMsg({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                });
                setLoading(false);
                setIsModalVisible(true);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
                setLoading(false);
            }
        } catch (error) {
            const errMsg = error.response ? error.response.data.error.message : error.message;
            message.error(errMsg);
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let isError = false;
        let newErrorObj = Object.assign({}, errorObj);
        for (let key in dataMsg) {
            if (key === "email") {
                if (!validateEmail(dataMsg[key])) {
                    newErrorObj[key] = "Wrong email format";
                    isError = true;
                }
            } else if (key === "phone") {
                if (!validateNumber(dataMsg[key])) {
                    newErrorObj[key] = "Please fill this field with number only";
                    isError = true;
                } else if (!validatePhoneNumber(dataMsg[key])) {
                    newErrorObj[key] = "Please fill this field with 08xxx format";
                    isError = true;
                }
            }

            if (!dataMsg[key]) {
                newErrorObj[key] = "Please fill this empty field";
                isError = true;
            }
        }

        if (!isError) {
            submitMessage();
        } else {
            setErrorObj(newErrorObj);
        }
    };

    const redirectWhatsapp = () => {
        window.open('https://wa.me/' + dataDetail.wa.link);
    };

    return (
        <WebLayout loading={loading} bgWhite={true} canScroll={true} colorIcon={true} ctx={props}>
            {/* <Helmet>
                <title>{seo.seo_title}</title>
                <meta name="description" content={seo.seo_desc} />
                <meta property='og:title' content={seo.seo_title}/>
                <meta property='og:description' content={seo.seo_desc}/>
            </Helmet> */}
            <div className="xg__container xg__container--full-margin">
                <Row>
                    <Col span={10} xs={24} sm={24} md={11}>
                        <div className="detail__title">
                            {dataDetail.page_title}
                        </div>
                        <p style={{ margin: '40px 0' }}>
                            {dataDetail.description}
                        </p>
                        <div className="contact__wa" onClick={() => redirectWhatsapp()}>
                            <img src={Whatsapp} alt="whatsapp-icon" />
                            <span>{dataDetail.whatsapp_text}</span>
                        </div>
                        <form id="lead-form" onSubmit={(e) => handleSubmit(e)}>
                            <div className="contact__form">
                                <div className="contact__form-field">
                                    <div className="contact__field-label">
                                        Your Name
                                    </div>
                                    <div>
                                        <Input
                                            className="contact__field"
                                            name="name"
                                            value={dataMsg.name}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <div className="contact__field--error">
                                            {errorObj.name}
                                        </div>
                                    </div>
                                </div>
                                <div className="contact__form-field">
                                    <div className="contact__field-label">
                                        Your Email
                                    </div>
                                    <div>
                                        <Input
                                            className="contact__field"
                                            name="email"
                                            value={dataMsg.email}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <div className="contact__field--error">
                                            {errorObj.email}
                                        </div>
                                    </div>
                                </div>
                                <div className="contact__form-field">
                                    <div className="contact__field-label">
                                        Your Phone Number
                                    </div>
                                    <div>
                                        <Input
                                            className="contact__field"
                                            name="phone"
                                            value={dataMsg.phone}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <div className="contact__field--error">
                                            {errorObj.phone}
                                        </div>
                                    </div>
                                </div>
                                <div className="contact__form-field">
                                    <div className="contact__field-label">
                                        Message
                                    </div>
                                    <div>
                                        <TextArea
                                            autoSize={{ minRows: 2, maxRows: 4 }}
                                            className="contact__field"
                                            name="message"
                                            value={dataMsg.message}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <div className="contact__field--error">
                                            {errorObj.message}
                                        </div>
                                    </div>
                                </div>
                                <Button className="btn btn__black" size={'large'} htmlType="submit">
                                    {dataDetail.button_contact_text || "Get in touch with us"}
                                </Button>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={process.env.REACT_APP_GAPTCHA_SITE_KEY}
                                />
                            </div>
                        </form>
                        {
                            (dataDetail.hiring_text && dataDetail.hiring_link_text) && (
                                <div className="contact__hiring">
                                    <span>{dataDetail.hiring_text}</span> <a href={dataDetail.email} target="_blank" rel="noreferrer" className="contact__link">{dataDetail.hiring_link_text} <img src={RightIcon} alt="right-icon" /></a>
                                </div>
                            )
                        }
                    </Col>
                    <Col offset={4} span={10} xs={{ offset: 0, span: 24 }} sm={{ offset: 0, span: 24 }} md={{ offset: 1, span: 11 }}>
                        <iframe className="contact__map" title="xg maps location" src={dataDetail.map || "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4751.143090433415!2d106.79580058631491!3d-6.200695705102812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f6bef2cf3359%3A0xb0d4a5e26eed1594!2sXtreme%20Graphics!5e0!3m2!1sen!2sid!4v1610100113401!5m2!1sen!2sid"} width="600" height="350" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0" />
                        <div className="contact__location-name">
                            {dataDetail.address_name || "Head Office - Jakarta"}
                        </div>
                        <p className="contact__location-address">
                            {dataDetail.address || "Grand Slipi Tower 8E JL. S. Parman Kav.22-24 Jakarta 11480, Indonesia"}
                        </p>
                    </Col>
                </Row>
            </div>
            <Modal
                visible={isModalVisible}
                closable={false}
                footer={null}
                width={325}
            >
                <div className="contact__modal-text">Thank you for contacting us. We will get back to you later!</div>
                <div className="contact__modal-button">
                    <Button className="btn btn__black" size={'large'} style={{ paddingLeft: '30px', paddingRight: '30px' }} onClick={() => setIsModalVisible(false)}>
                        OK
                    </Button>
                </div>
            </Modal>
        </WebLayout>
    );
};

export default Contact;