const getBaseUrl = process.env.REACT_APP_API_URL;

export const apiGetContact = () => ({
    method: 'POST',
    url: `${getBaseUrl}/contact-us/get`,
    isAuthorize: true,
});

export const apiPostContactMsg = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/contact-us/post`,
    isAuthorize: true,
});