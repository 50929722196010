import Coffee from '../../../assets/images/coffee.svg';

const Footer = (props) => {
    const {
        fixedFooter,
    } = props;
    const redirectWhatsapp = () => {
        window.open('https://wa.me/' + props.wa);
    };

    return (
        <div className={"footer" + (fixedFooter ? " footer__fixed" : "")}>
            <div className="footer__content">
                <span>Got a Project?</span> <span className="footer__link" onClick={() => redirectWhatsapp()}>Let's Talk <img className="footer__image" src={Coffee} alt="coffee-cup"/></span>
            </div>
        </div>
    );
};

export default Footer;