import { Fragment, useEffect, useState } from "react";
import {
    Row,
    Col,
    Tooltip,
    message,
} from 'antd';
import LinesEllipsis from 'react-lines-ellipsis';
import moment from 'moment';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
} from "react-share";
import {
    apiGetBlogsDetail,
    apiPostBlogsTracker,
} from '../../api/blogs';
import {
    apiGetWorksDetail,
    apiPostWorksTracker,
} from '../../api/works';
import LeftIcon from '../../assets/images/left.svg';
import Share from '../../assets/images/share.svg';
import Facebook from '../../assets/images/facebook-black.svg';
import Linkedin from '../../assets/images/linkedin-black.svg';
import Twitter from '../../assets/images/twitter.svg';
import WebLayout from '../../components/layout/webLayout';
// import RouteRedirect from '../../utils/routeRedirect';
import { errorHandling } from '../../utils/errorHandling';
import { RequestAPI } from '../../utils/requestApi';
import {
    isEmpty,
} from '../../utils/validations';

const Detail = (props) => {
    const [getData, setGetData] = useState(true);
    const [scrollTrigger, setScrollTrigger] = useState(false);
    const [bottomTrigger ,setBottomTrigger] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [pageState, setPageState] = useState('works');
    const [dataList, setDataList] = useState([]);
    const [dataDetail, setDataDetail] = useState('');
    const {
        match,
    } = props;

    const fetchDetailWorks = async (id) => {
        try {
            const response = await RequestAPI(apiGetWorksDetail(id));
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                if (!isEmpty(resData.detail)) {
                    setDataDetail(resData.detail);
                    setDataList(resData.related_works);
                } else {
                    window.location.href = process.env.REACT_APP_BASE_URL + '/404';
                }
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
            }
        } catch (error) {
            const errMsg = errorHandling(error);
            if (errMsg) {
                message.error(errMsg);
            }
        }
    };

    const fetchDetailBlogs = async (id) => {
        try {
            const response = await RequestAPI(apiGetBlogsDetail(id));
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                if (!isEmpty(resData.detail)) {
                    setDataDetail(resData.detail);
                    setDataList(resData.related_blogs);
                } else {
                    window.location.href = process.env.REACT_APP_BASE_URL + '/404';
                }
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
            }
        } catch (error) {
            const errMsg = errorHandling(error);
            if (errMsg) {
                message.error(errMsg);
            }
        }
    };

    useEffect(() => {
        const url = match.path;
        const stringId = match.params.id;
        const urlText = url.split("/");
        const token = localStorage.getItem('token');
        if (getData && token) {
            if (urlText[1].includes("works")) {
                setPageState('works');
                fetchDetailWorks(stringId);
            } else if (urlText[1].includes("blogs")) {
                setPageState('blogs');
                fetchDetailBlogs(stringId);
            }
            setGetData(false);
        }

        const elmContainer = document.getElementById('container');
        elmContainer.addEventListener('scroll', (e) => {
            const elmDetail = document.getElementById('detail-intro');
            const screenPosition = elmDetail.getBoundingClientRect();
            if ((screenPosition.top - 16) <= 0) {
                if ((screenPosition.bottom - 300) <= 0) {
                    setScrollTrigger(false);
                    setBottomTrigger(true);
                } else if ((screenPosition.bottom - 300) > 0) {
                    setScrollTrigger(true);
                    setBottomTrigger(false);
                } else {
                    setScrollTrigger(true);
                }
            } else if ((screenPosition.top - 16) > 0) {
                setScrollTrigger(false);
            }
        });
        
        const elem = document.getElementById('scroll-wrapper');
        let mousewheelevt = (/Firefox/i.test(navigator.userAgent))? "DOMMouseScroll" : "mousewheel";

        elem.addEventListener(mousewheelevt, (e) => {
            let delta = e.detail? e.detail*(-25) : e.wheelDelta / 3;
            const scrollPosition = elem.scrollLeft -= delta;
            const fullWidth = elem.clientWidth;
            const fullScroll = elem.scrollWidth;
            if (scrollPosition > 150 && (fullScroll - scrollPosition > fullWidth)) {
                elem.scrollLeft -= delta;
                e.preventDefault();
            }
        });
        
        const elm = document.getElementById('banner-scroll-wrapper');
        if (elm) {
            elm.addEventListener(mousewheelevt, (e) => {
                let delta = e.detail? e.detail*(-25) : e.wheelDelta / 3;
                const scrollPosition = elm.scrollLeft -= delta;
                const fullWidth = elm.clientWidth;
                const fullScroll = elm.scrollWidth;
                if (scrollPosition > 150 && (fullScroll - scrollPosition > fullWidth)) {
                    elm.scrollLeft -= delta;
                    e.preventDefault();
                }
            });
        };
    // eslint-disable-next-line
    }, [getData]);

    const copyToClipBoard = (str) => {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    const copyText = (e) => {
        const text = e.target.baseURI;
        copyToClipBoard(text);

        setTooltipVisible(true);
        setTimeout(() => { setTooltipVisible(false); }, 1500);
    };

    const shareMedia = async (media, e) => {
        const url = match.path;
        const stringId = match.params.id;
        const urlText = url.split("/");
        try {
            const body = {
                string_id: stringId,
                platform: media,
            };
            let response;
            if (urlText[1].includes("works")) {
                response = await RequestAPI(apiPostWorksTracker(body));
            } else {
                response = await RequestAPI(apiPostBlogsTracker(body));
            }
            if (response.status === 200 || response.status === 201) {
                if (e) {
                    copyText(e);
                } else {
                    let elem = document.getElementById(media);
                    elem.click();
                }
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
            }
        } catch (error) {
            const errMsg = errorHandling(error);
            if (errMsg) {
                message.error(errMsg);
            }
        }
    };

    const changeBackground = (e, img) => {
        e.target.style.backgroundImage = `url(${img})`;
    };

    return (
        <WebLayout bgWhite={true} canScroll={true} colorIcon={true} withFooter={true} ctx={props}>
            <div className="xg__container">
                <Row>
                    <Col span={16}>
                        <div>
                            <a href={process.env.REACT_APP_BASE_URL + "/" + pageState}>
                                <div className="detail__back">
                                    <img src={LeftIcon} alt={"left-chevron"} /> <span>{pageState === "works" ? dataDetail.back_title ? dataDetail.back_title : "Back to Works" : dataDetail.back_title ? dataDetail.back_title : "Back to Blogs" }</span>
                                </div>
                            </a>
                        </div>
                    </Col>
                </Row>
            </div>
            {
                pageState === "works" ? 
                    (
                        <Fragment>
                            {
                                (dataDetail && dataDetail.xg_work_images.length > 0) && (
                                    <div id="banner-scroll-wrapper" className="lists__wrapper detail__others">
                                        {
                                            dataDetail.xg_work_images.map((item, index) => {
                                                return (
                                                    <div className="about__scroll-image about__scroll-image--vertical-middle" key={index}>
                                                        <img src={item.image} alt={"works-"+index} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                            <div className="xg__container">
                                <Row>
                                    <Col span={16} xs={24} sm={24} md={16}>
                                        <div className="detail__banner-subtitle">
                                            {dataDetail.sub_title}
                                        </div>
                                        <div className="detail__banner-title" style={{ color: dataDetail.title_color }}>
                                            {dataDetail.title}
                                        </div>
                                        <div className="detail__info">
                                            {dataDetail.categories}
                                        </div>
                                        <div className="detail__info">
                                            {dataDetail.project_month}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Fragment>
                    )
                : 
                    (
                        <div className="xg__container" style={{ margin: 0 }}>
                            <Row>
                                <Col span={16} xs={24} sm={24} md={16}>
                                    <div className="detail__banner">
                                        <img
                                            src={dataDetail.images}
                                            alt={"blog-img"}
                                        />
                                    </div>
                                    <div className="detail__title">
                                        {dataDetail.title}
                                    </div>
                                    <div className="detail__info" style={{ margin: '4px 0' }}>
                                        {moment(dataDetail.created_at).format("DD MMM YYYY")} • {dataDetail.categories}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )
            }
            <div id="detail-intro" className="xg__container detail__desc-container" style={{ marginTop: 0 }}>
                <Row>
                    <Col span={16} xs={24} sm={24} md={16} lg={16}>
                        <div className="detail__intro-desc">
                            {dataDetail.big_paragraf}
                        </div>
                        <div className="detail__desc" dangerouslySetInnerHTML={{__html: dataDetail.content}} />
                    </Col>
                </Row>
                <div className={"detail__social-media " + (pageState === "works" ? "detail__social-works " : "") + (bottomTrigger ? "detail__social-media--bottom " : "") + (!scrollTrigger ? "detail__social-media--absolute" : "")}>
                    <div className="detail__social-title">Share</div>
                    <div id="shareicon" style={{ position: 'relative' }}>
                        <Tooltip visible={tooltipVisible} placement="left" title={"Link Copied!"} trigger="click" onClick={(e) => shareMedia('copy', e)} getPopupContainer={() => document.getElementById('shareicon')}>
                            <img src={Share} alt="share-icon"/>
                        </Tooltip>
                    </div>
                    <div>
                        <FacebookShareButton
                            id="facebook"
                            url={window.location.href}
                            style={{ display: 'none' }}
                        />
                        <div onClick={() => {
                            shareMedia('facebook');
                        }}>
                            <img src={Facebook} alt="facebook-icon"/>
                        </div>
                    </div>
                    <div>
                        <TwitterShareButton
                            id="twitter"
                            url={window.location.href}
                            style={{ display: 'none' }}
                        />
                        <div onClick={() => {
                            shareMedia('twitter');
                        }}>
                            <img src={Twitter} alt="twitter-icon"/>
                        </div>
                    </div>
                    <div>
                        <LinkedinShareButton
                            id="linkedin"
                            url={window.location.href}
                            style={{ display: 'none' }}
                        />
                        <div onClick={() => {
                            shareMedia('linkedin');
                        }}>
                            <img src={Linkedin} alt="linkedin-icon"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="detail__others">
                <div className="xg__container">
                    <div className="detail__sub-title">
                        {pageState === "works" ? dataDetail.browse_other_text ? dataDetail.browse_other_text : "Browse other Branding project" : "Browse other Blogs"}
                    </div>
                </div>
                <div id="scroll-wrapper" className="lists__wrapper">
                    {
                        dataList.map((item, index) => {
                            return (
                                <div className="lists__content" key={index}>
                                    {
                                        pageState === "works" ?
                                            (
                                                <div style={{cursor: 'pointer'}}>
                                                    <a href={process.env.REACT_APP_BASE_URL + '/' + pageState + '/' + item.string_id}>
                                                        <div 
                                                            className={"lists__content-thumbnail" + (!item.images_hover ? " lists__content-thumbnail--hover" : "")}
                                                            style={{ backgroundImage: `url(${item.images})` }}
                                                            onMouseEnter={(e) => item.images_hover && changeBackground(e, item.images_hover)}
                                                            onMouseLeave={(e) => item.images_hover && changeBackground(e, item.images)}
                                                        />
                                                    </a>
                                                </div>
                                            )
                                        :
                                            (
                                                <div style={{cursor: 'pointer'}}>
                                                    <a href={process.env.REACT_APP_BASE_URL + '/' + pageState + '/' + item.string_id}>
                                                        <div 
                                                            className={"lists__content-thumbnail lists__content-thumbnail--hover"}
                                                            style={{ backgroundImage: `url(${item.images})` }}
                                                        />
                                                    </a>
                                                </div>
                                            )
                                    }
                                    <div className="lists__content-text-wrapper">
                                        {
                                            ((pageState === "works" && item.work_list_title) || (pageState === "blogs" && item.title)) && (
                                                <div className="lists__content-title">
                                                    <a href={process.env.REACT_APP_BASE_URL + '/' + pageState + '/' + item.string_id}>
                                                        <LinesEllipsis
                                                            text={pageState === "works" ? item.work_list_title : item.title}
                                                            maxLine={'2'}
                                                            ellipsis='...'
                                                            basedOn='words'
                                                        />
                                                    </a>
                                                </div>
                                            )
                                        }
                                        {
                                            pageState === "works" ?
                                                (
                                                    <div style={{ margin: '4px 0' }}>
                                                        {item.categories} • {moment(item.date).format("MMM YYYY")} 
                                                    </div>
                                                )
                                            :
                                                (
                                                    <div style={{ margin: '4px 0' }}>
                                                        {item.categories} • {moment(item.date).format("DD MMM YYYY")}
                                                    </div>
                                                )
                                        }
                                        {
                                            ((pageState === "works" && item.work_list_description) || (pageState === "blogs" && item.blog_list_description)) && (
                                                <p className="lists__content-desc">
                                                    <LinesEllipsis
                                                        text={pageState === "works" ? item.work_list_description : item.blog_list_description}
                                                        maxLine={'2'}
                                                        ellipsis='...'
                                                        basedOn='words'
                                                    />
                                                </p>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </WebLayout>
    );
};

export default Detail;