import {
    Button,
} from 'antd';
// import { Helmet } from "react-helmet";
import Mokapot from '../../assets/images/mokapot.gif';
import WebLayout from '../../components/layout/webLayout';
// import RouteRedirect from '../../utils/routeRedirect';

const NotFound = (props) => {
    // const {
    //     seo,
    // } = props;
    
    return (
        <WebLayout bgWhite={true} canScroll={true} colorIcon={true} ctx={props}>
            <div className="xg__container">
                {/* <Helmet>
                    <title>{seo.seo_title}</title>
                    <meta name="description" content={seo.seo_description} />
                    <meta property='og:title' content={seo.seo_title}/>
                    <meta property='og:description' content={seo.seo_description}/>
                </Helmet> */}
                <div className="notfound">
                    <div className="notfound__title">
                        404
                    </div>
                    <div className="notfound__desc">
                        Sorry, we can’t find the page you’re looking for.
                    </div>
                    <img src={Mokapot} alt="mokapot icon" />
                    <a href={process.env.REACT_APP_BASE_URL}>
                        <Button className="btn btn__black" size={'large'}>
                            <span>Back to Home</span>
                        </Button>
                    </a>
                </div>
            </div>
        </WebLayout>
    )
};

export default NotFound;