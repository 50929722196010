import { useEffect, useState } from "react";
import {
    Select,
    message,
} from 'antd';
import LinesEllipsis from 'react-lines-ellipsis';
import moment from 'moment';
// import { Helmet } from "react-helmet";
import {
    apiGetBlogsList,
    apiGetBlogsCopytext,
    apiGetBlogsCategories,
} from '../../api/blogs';
import {
    apiGetWorksList,
    apiGetWorksCopytext,
    apiGetWorksCategories,
} from '../../api/works';
import WebLayout from '../../components/layout/webLayout';
import { errorHandling } from '../../utils/errorHandling';
import { RequestAPI } from '../../utils/requestApi';

const { Option } = Select;

const Lists = (props) => {
    const [getData, setGetData] = useState(true);
    const [categories, setCategories] = useState(0);
    const [categoryList, setCategoryList] = useState([]);
    const [pageState, setPageState] = useState('works');
    const [dataList, setDataList] = useState([]);
    const [dataDetail, setDataDetail] = useState({
        title: '',
        seo_title: '',
        seo_description: '',
    });
    const {
        match,
        seo,
    } = props;

    const fetchList = async (type, cat) => {
        try {
            let response;
            if (type === "works") {
                response = await RequestAPI(apiGetWorksList(cat));
            } else if (type === "blogs") {
                response = await RequestAPI(apiGetBlogsList(cat));
            }
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                setDataList(resData);
                setCategories(cat);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
            }
        } catch (error) {
            const errMsg = errorHandling(error);
            if (errMsg) {
                message.error(errMsg);
            }
        }
    };

    const fetchCopyText = async (type) => {
        try {
            let response;
            if (type === "works") {
                response = await RequestAPI(apiGetWorksCopytext());
            } else if (type === "blogs") {
                response = await RequestAPI(apiGetBlogsCopytext());
            }
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let newDataDetail = Object.assign({}, dataDetail);
                newDataDetail.title = type === "works" ? resData.work_list_title : resData.blog_list_title;
                newDataDetail.seo_title = seo.seo_title;
                newDataDetail.seo_description = seo.seo_desc;

                setDataDetail(newDataDetail);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
            }
        } catch (error) {
            const errMsg = errorHandling(error);
            if (errMsg) {
                message.error(errMsg);
            }
        }
    };

    const fetchCategory = async (type) => {
        try {
            let response;
            if (type === "works") {
                response = await RequestAPI(apiGetWorksCategories());
            } else if (type === "blogs") {
                response = await RequestAPI(apiGetBlogsCategories());
            }
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let optionObj = [];
                resData.map((item) => {
                    if (item.status === "Active") {
                        const dataObj = {
                            value: item.id,
                            label: item.name,
                        };

                        optionObj.push(dataObj);
                        return dataObj;
                    } else {
                        return item;
                    }
                });
                setCategoryList(optionObj);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
            }
        } catch (error) {
            const errMsg = errorHandling(error);
            if (errMsg) {
                message.error(errMsg);
            }
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (getData && token) {
            const url = match.path;
            if (url.includes("works")) {
                setPageState('works');
                fetchList('works', categories);
                fetchCopyText('works');
                fetchCategory('works');
            } else if (url.includes("blogs")) {
                setPageState('blogs');
                fetchList('blogs', categories);
                fetchCopyText('blogs');
                fetchCategory('blogs');
            }
            setGetData(false);
        }
        const elem = document.getElementById('scroll-wrapper');
        let mousewheelevt = (/Firefox/i.test(navigator.userAgent))? "DOMMouseScroll" : "mousewheel";

        elem.addEventListener(mousewheelevt, (e) => {
            let delta = e.detail? e.detail*(-25) : e.wheelDelta / 3;
            const scrollPosition = elem.scrollLeft -= delta;
            const fullWidth = elem.clientWidth;
            const fullScroll = elem.scrollWidth;
            if (scrollPosition > 150 && (fullScroll - scrollPosition > fullWidth)) {
                elem.scrollLeft -= delta;
                e.preventDefault();
            }
        })
    // eslint-disable-next-line
    }, [getData]);

    const handleChange = (e) => {
        const value = e.target ? e.target.value : e;

        fetchList(pageState, value);
    };

    const changeBackground = (e, img) => {
        e.target.style.backgroundImage = `url(${img})`;
    };

    return (
        <WebLayout bgWhite={true} canScroll={true} colorIcon={true} withFooter={true} fixedFooter={true} ctx={props}>
            {/* <Helmet>
                <title>{dataDetail.seo_title}</title>
                <meta name="description" content={dataDetail.seo_description} />
                <meta property='og:title' content={dataDetail.seo_title}/>
                <meta property='og:description' content={dataDetail.seo_description}/>
            </Helmet> */}
            <div className="lists__container">
                <div className="xg__container lists__container-wrap">
                    <h1 className="lists__title">
                        {pageState === "works" ? dataDetail.title : dataDetail.title}
                    </h1>
                    <div className="lists__filter">
                        <div className="lists__filter_content">
                            <div className="lists__showtext">
                                Show
                            </div>
                        </div>
                        <div className="lists__filter_content">
                            <Select value={categories} className="input lists__input-select" style={{ width: '100%' }} onChange={(e) => handleChange(e)}>
                                <Option key={0} value={0}>All Categories</Option>
                                {
                                    categoryList.map((item, index) => {
                                        return (
                                            <Option key={index + 1} value={item.value}>{item.label}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                </div>
                <div id="scroll-wrapper" className="lists__wrapper">
                    {
                        dataList.map((item, index) => {
                            return (
                                <div className="lists__content" key={index}>
                                    {
                                        pageState === "works" ?
                                            (
                                                <div className="lists__content-warp">
                                                    <a href={process.env.REACT_APP_BASE_URL + '/' + pageState + '/' + item.string_id}>
                                                        <div 
                                                            className={"lists__content-thumbnail" + (!item.images_hover ? " lists__content-thumbnail--hover" : "")}
                                                            style={{ backgroundImage: `url(${item.images})` }}
                                                            onMouseEnter={(e) => item.images_hover && changeBackground(e, item.images_hover)}
                                                            onMouseLeave={(e) => item.images_hover && changeBackground(e, item.images)}
                                                        />
                                                    </a>
                                                </div>
                                            )
                                        :
                                            (
                                                <div className="lists__content-warp">
                                                    <a href={process.env.REACT_APP_BASE_URL + '/' + pageState + '/' + item.string_id}>
                                                        <div 
                                                            className={"lists__content-thumbnail lists__content-thumbnail--hover"}
                                                            style={{ backgroundImage: `url(${item.images})` }}
                                                        />
                                                        {/* <img 
                                                            className="lists__content-thumbnail-image lists__content-thumbnail--hover"
                                                            src={item.images} 
                                                            alt={"thumbnail-img-"+index}
                                                        /> */}
                                                    </a>
                                                </div>
                                            )
                                    }
                                    <div className="lists__content-text-wrapper">
                                        {
                                            ((pageState === "works" && item.work_list_title) || (pageState === "blogs" && item.title)) && (
                                                <div title={pageState === "works" ? item.work_list_title : item.title} className="lists__content-title">
                                                    <a href={process.env.REACT_APP_BASE_URL + '/' + pageState + '/' + item.string_id}>
                                                        <LinesEllipsis
                                                            text={pageState === "works" ? item.work_list_title : item.title}
                                                            maxLine={'2'}
                                                            ellipsis='...'
                                                            basedOn='words'
                                                        />
                                                    </a>
                                                </div>
                                            )
                                        }
                                        {
                                            pageState === "works" ?
                                                (
                                                    <div className="lists__category" style={{ margin: '4px 0' }}>
                                                        {item.categories} • {moment(item.date).format("MMM YYYY")}
                                                    </div>
                                                )
                                            :
                                                (
                                                    <div className="lists__category" style={{ margin: '4px 0' }}>
                                                        {item.categories} • {moment(item.date).format("DD MMM YYYY")} 
                                                    </div>
                                                )
                                        }
                                        {
                                            ((pageState === "works" && item.work_list_description) || (pageState === "blogs" && item.blog_list_description)) && (
                                                <p title={item.work_list_description} className="lists__content-desc">
                                                    <LinesEllipsis
                                                        text={pageState === "works" ? item.work_list_description : item.blog_list_description}
                                                        maxLine={'2'}
                                                        ellipsis='...'
                                                        basedOn='words'
                                                    />
                                                </p>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </WebLayout>
    );
};

export default Lists;