const getBaseUrl = process.env.REACT_APP_API_URL;

export const apiGetSlider = () => ({
    method: 'POST',
    url: `${getBaseUrl}/sliders/get`,
    isAuthorize: true,
});

export const apiGetHomeSEO = () => ({
    method: 'POST',
    url: `${getBaseUrl}/home/get-seo`,
    isAuthorize: true,
});