import Axios from 'axios';
import {
  message,
} from 'antd';
import { toLower } from 'lodash';
import qs from 'qs';
import moment from 'moment';
import { apiGetToken } from '../api/getToken';

const CancelToken = Axios.CancelToken;
let cancel;
let isGetNewToken = false;
let isTokenExpired = false;

export const jsonToQueryString = (json) =>
  Object.keys(json)
    .map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
    })
    .join('&');

export const RequestAPI = async (data) => {
  const { method, url, headerAdditional, params, isAuthorize = false, uploadProgress } = data;
  let { body } = data;

  let headers = { 'Content-Type': 'application/json' };

  if (headerAdditional) {
    headers = Object.assign({}, headers, headerAdditional);
  }

  // if (headers['Content-Type'] === 'application/x-www-form-urlencoded') {
  //   body = jsonToQueryString(data.body);
  // }

  if (isAuthorize) {
    const token = localStorage.getItem('token');
    const expDate = localStorage.getItem('expired_date');
    const d = new Date();
    const today = moment(d);
    const expiredDate = moment(expDate);
    
    if (token && today <= expiredDate) {
      Object.assign(headers, {
          'xg-h-token': `${token}`,
      });
    } else if (!token && !isGetNewToken) {
      isGetNewToken = true;
      try {
        const response = await RequestAPI(apiGetToken());
        if (response.status === 200 || response.status === 201) {
          const resData = response.data.data;
          localStorage.setItem('token', resData.token_code);
          localStorage.setItem('expired_date', resData.expired_at);
          Object.assign(headers, {
            'xg-h-token': `${resData.token_code}`,
          });
        } else {
          const errMsg = response.error.message;
          message.error(errMsg);
        }
      } catch (error) {
        const errMsg = error.response ? error.response.data.error.message : error.message;
        message.error(errMsg);
      }
    }
  }

  let Action;
  if (toLower(method) === 'get') {
    const configAxios = {
      headers,
      method,
      params,
      paramsSerializer: (param) => qs.stringify(param, { encode: false }),
      url,
    };

    Action = Axios(configAxios);
  } else {
    const BODY = { 
      method, 
      url, 
      headers, 
      data: body, 
      params,
      onUploadProgress: function(progressEvent) {
        if (uploadProgress) {
          uploadProgress(progressEvent);
        }
      },
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      }),
    };

    Action = Axios(BODY);
  }

  try {
    const response = await Action;
    return response;
  } catch (error) {
    if (error.response && error.response.data) {
      if (error.response && error.response.status === 401 && error.response.statusText === "Unauthorized" && !isTokenExpired) {
        isTokenExpired = true;
        localStorage.clear();
        window.location.reload();
        throw error;
      } else {
        throw error;
      }
    } else {
      throw error;
    }
  }
};

export const cancelRequest = () => {
  cancel('Upload progress has been cancelled.');
}

export default RequestAPI;
