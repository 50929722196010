import React, { useEffect, useState } from "react";
import {
  message,
} from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ReactGA from 'react-ga';
import { apiGetGeneralSetting } from '../api/socialMedia';
import Transitions from '../components/transitions';
import Home from '../pages/home';
import About from '../pages/about';
import Detail from '../pages/detail';
import Lists from '../pages/lists';
import Contact from '../pages/contact';
import NotFound from '../pages/not-found';
import history from '../utils/history';
import { errorHandling } from '../utils/errorHandling';
import { RequestAPI } from '../utils/requestApi';

const AppRouter = () => {
  const [getData, setGetData] = useState(true);
  const [gaKey, setGaKey] = useState('');
  const [objSEO, setObjSEO] = useState({
    seo_title: '',
    seo_desc: '',
  });

  const fetchGeneralData = async () => {
    try {
      const response = await RequestAPI(apiGetGeneralSetting());
      if (response.status === 200 || response.status === 201) {
        const resData = response.data.data;
        setGaKey(resData.ga_key);
        setObjSEO({
          seo_title: resData.seo_meta_title,
          seo_desc: resData.seo_meta_description,
        });
      } else {
        const errMsg = response.error.message;
        message.error(errMsg);
      }
    } catch (error) {
      const errMsg = errorHandling(error);
      if (errMsg) {
        message.error(errMsg);
      }
    }
  };

  useEffect(() => {
    if (getData) {
      fetchGeneralData();
      setGetData(false);
    }
    // eslint-disable-next-line
  }, [getData]);

  const XGRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props => {
          return (
            <Component {...props} seo={objSEO} />
          )
        }}
      />
    )
  };

  // if (gaKey && (process.env.REACT_APP_ENV === "staging" || process.env.REACT_APP_ENV === "production")) {
  //   ReactGA.initialize(gaKey);
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }

  return (
    <Router history={history}>
      <Route
        render={({ location }) => {
          return (
            <Transitions pageKey={location.key} bgColor={location.bgColor} {...location.state}>
              <Switch location={location}>
                <XGRoute exact={true} path="/" component={Home}></XGRoute>
                <XGRoute exact={true} path="/about" component={About}></XGRoute>
                <XGRoute exact={true} path="/works" component={Lists}></XGRoute>
                <XGRoute exact={true} path="/works/:id" component={Detail}></XGRoute>
                <XGRoute exact={true} path="/blogs" component={Lists}></XGRoute>
                <XGRoute exact={true} path="/blogs/:id" component={Detail}></XGRoute>
                <XGRoute exact={true} path="/contact" component={Contact}></XGRoute>
                <XGRoute exact={true} path="/404" component={NotFound} ></XGRoute>
                <XGRoute component={NotFound} ></XGRoute>
              </Switch>
            </Transitions>
          )
        }}
      />
    </Router>
  );
};
export default AppRouter;