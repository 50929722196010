const getBaseUrl = process.env.REACT_APP_API_URL;

export const apiGetWorksList = (idCategory) => ({
    body: {
        category: idCategory,
    },
    method: 'POST',
    url: `${getBaseUrl}/works/get`,
    isAuthorize: true,
});

export const apiGetWorksCopytext = () => ({
    method: 'POST',
    url: `${getBaseUrl}/works/get-works-copytexts`,
    isAuthorize: true,
});

export const apiGetWorksCategories = () => ({
    method: 'POST',
    url: `${getBaseUrl}/works/get-works-categories`,
    isAuthorize: true,
});

export const apiGetWorksDetail = (id) => ({
    body: {
        string_id: id,
    },
    method: 'POST',
    url: `${getBaseUrl}/works/detail`,
    isAuthorize: true,
});

export const apiPostWorksTracker = (body) => ({
    body,
    method: 'POST',
    url: `${getBaseUrl}/works/post-tracker`,
    isAuthorize: true,
});