// import RouteRedirect from '../../../utils/routeRedirect';
import { Fragment } from 'react';

const Header = (props) => {
    const {
        // ctx,
        colorIcon,
        bgWhite,
        noMenu,
        showMenu,
        clickMenu,
    } = props;

    return (
        <Fragment>

            <div className="header" style={{ backgroundColor: (bgWhite ? '#fff' : 'transparent') }}>
                <div className="header__content header__content--left">
                    <a href={process.env.REACT_APP_BASE_URL}>
                        <div className={"header__logo header__logo--big" + (colorIcon ? " header__logo--color" : "")}>
                            <svg width="214" height="146" viewBox="0 0 214 146" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M210.545 73.0491H195.549C193.687 73.0491 192.217 74.5192 192.217 76.3813V114.506C192.217 116.074 191.335 117.446 189.963 118.23C181.828 122.445 172.518 124.601 162.619 123.915C150.074 123.033 138.803 117.446 130.375 109.018L112.047 90.6904C110.773 89.4163 108.617 89.4163 107.343 90.6904L96.7582 101.373C95.4841 102.647 95.4841 104.803 96.7582 106.077L115.086 124.405C128.219 137.538 146.448 145.77 166.441 145.77C183.495 145.77 199.666 139.792 211.721 129.991C213.093 128.913 213.877 127.247 213.877 125.483V76.4793C213.877 74.5192 212.407 73.0491 210.545 73.0491Z" fill="white"/>
                                <path d="M210.741 15.4208C197.804 5.4241 181.338 -0.260318 163.599 0.425732C144.782 1.11178 127.729 9.14837 115.184 21.6933L96.7582 40.1186C95.4842 41.3927 95.4842 43.5489 96.7582 44.823L107.343 55.4078C108.617 56.6819 110.773 56.6819 112.047 55.4078L130.473 36.9824C139.685 27.7697 152.426 22.0853 166.441 22.0853C177.418 22.0853 187.611 25.6136 196.04 31.592C197.412 32.5721 199.176 32.3761 200.352 31.2L211.133 20.4192C212.407 19.0471 212.309 16.6949 210.741 15.4208Z" fill="white"/>
                                <path d="M26.0951 6.30615H2.27933C0.417196 6.30615 -0.464869 8.56032 0.809225 9.83441L62.1617 71.1869C63.1418 72.167 63.1418 73.7351 62.1617 74.7152L0.809225 136.068C-0.464869 137.342 0.417196 139.596 2.27933 139.596H26.1931C27.2712 139.596 28.3492 139.204 29.1333 138.42L92.348 75.3032C93.6221 74.0291 93.6221 71.873 92.348 70.5989L29.1333 7.58025C28.3492 6.79619 27.2712 6.30615 26.0951 6.30615Z" fill="white"/>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
            <div className="header__content header__content--right">
                {
                    !noMenu && (
                        <div className={"header__logo" + (colorIcon ? " header__logo--color" : "") + (showMenu ? " header__logo--menu" : "")} onClick={() => clickMenu()}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect className={"line__top" + (showMenu ? " active" : "")} x="2" y="8" width="20" height="2" rx="0.2" fill="white"/>
                                <rect className={"line__bottom" + (showMenu ? " active" : "")} x="2" y="14" width="20" height="2" rx="0.2" fill="white"/>
                            </svg>
                        </div>
                    )
                }
            </div>
        </Fragment>
    );
};

export default Header;