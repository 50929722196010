import { useEffect, useState } from 'react';
import {
    Row,
    Col,
    message,
} from 'antd';
import { apiGetSocialMedia } from '../../../api/socialMedia';
// import Close from '../../../assets/images/close.svg';
import RightIcon from '../../../assets/images/right.svg';
// import { right } from '../../../components/transitions';
// import RouteRedirect from '../../../utils/routeRedirect';
import { errorHandling } from '../../../utils/errorHandling';
import { RequestAPI } from '../../../utils/requestApi';

const FixedMenu = (props) => {
    const [getData, setGetData] = useState(true);
    const [sosmedData, setSosmedData] = useState([]);
    const {
        // ctx,
        noMenu,
        showMenu,
        fixed,
        clickClose,
        setWA,
    } = props;

    const fetchSocialMedia = async () => {
        try {
            const response = await RequestAPI(apiGetSocialMedia());
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let data = [];
                resData.socmed.map((item) => {
                    let dataObj = {};
                    if (item.status === "Active" && item.name !== "WA") {
                        dataObj = {
                            img: item.logo,
                            url: item.link,
                            label: item.name,
                        };
                        data.push(dataObj);
                    }
                    return dataObj;
                });
                if (setWA) {
                    setWA(resData.wa.link);
                }
                setSosmedData(data);
            }
        } catch (error) {
            const errMsg = errorHandling(error);
            if (errMsg) {
                message.error(errMsg);
            }
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (getData && token) {
            fetchSocialMedia();
            setGetData(false);
        }
    // eslint-disable-next-line
    },[getData]);

    const list_menu = [
        {
            label: 'About',
            url: 'about',
        },
        {
            label: 'Works',
            url: 'works',
        },
        {
            label: 'Blogs',
            url: 'blogs',
        },
        {
            label: 'Contact',
            url: 'contact',
        },
    ];

    return (
        <div className={"menu " + (showMenu ? "menu--show" : "") + (noMenu ? "menu--fixed" : "") + (fixed ? " menu--stay" : "")}>
            {
                showMenu && (
                    <div className="menu__overlay" onClick={() => clickClose()}/>
                )
            }
            <div className="menu__wrapper">
                <div className="menu__contentwrap">
                    {/* {
                        !noMenu && (
                            <div className="menu__close">
                                <img src={Close} alt="close icon" onClick={() => clickClose()} />
                            </div>
                        )
                    } */}
                    <div className="menu__content">
                        <div className="menu__list">
                            {
                                list_menu.map((item, index) => {
                                    return (
                                        <div key={index} className="menu__list_name">
                                            <a className="menu__list_name--link" href={process.env.REACT_APP_BASE_URL + "/" + item.url} /*onClick={() => RouteRedirect(ctx, item.url, 'right')} onTouchEnd={() => RouteRedirect(ctx, item.url, 'right')}*/>
                                                {item.label}
                                                <div className="menu__list_name--icon">
                                                    <img src={RightIcon} alt="right-icon"/>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="menu__footer">
                            <div className="menu__social">
                                <Row justify="end">
                                    {
                                        sosmedData.map((item, index) => {
                                            if ((sosmedData.length - 1) === index) {
                                                return (
                                                    <Col key={index} style={{ marginLeft: '16px' }}>
                                                        <a href={item.url} target="_blank" rel="noreferrer">
                                                            <img src={item.img} alt={item.label +"-icon"}/>
                                                        </a>
                                                    </Col>
                                                )
                                            } else {
                                                return (
                                                    <Col key={index} style={{ margin: '0 16px' }}>
                                                        <a href={item.url} target="_blank" rel="noreferrer">
                                                            <img src={item.img} alt={item.label +"-icon"}/>
                                                        </a>
                                                    </Col>
                                                )
                                            }
                                        })
                                    }
                                </Row>
                            </div>
                            <p className="menu__footer_text">
                                ©2020 Xtreme Graphics All Rights Reserved
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FixedMenu;