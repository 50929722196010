import { Fragment, useState } from "react";
import {
    Spin,
} from 'antd';
import FixedMenu from '../../organism/fixedMenu';
import Header from '../../organism/header';
import Footer from '../../organism/footer';

const WebLayout = (props) => {
    const [showMenu, setShowMenu] = useState(false);
    const [wa, setWA] = useState('');
    const {
        ctx,
        colorIcon,
        noMenu,
        withFooter,
        bgWhite,
        canScroll,
        children,
        fixedFooter,
        loading,
    } = props;

    const clickMenu = () => {
        setShowMenu(!showMenu);
    };

    const clickClose = () => {
        setShowMenu(false);
    };

    return (
        <Fragment>
            {
                loading && (
                    <div className="cms__loading">
                        <div className="cms__spinner">
                            <Spin/>
                        </div>
                    </div>
                )
            }
            <Header bgWhite={bgWhite} noMenu={noMenu} showMenu={showMenu} colorIcon={colorIcon} ctx={ctx} clickMenu={clickMenu}/>
            <FixedMenu showMenu={showMenu} ctx={ctx} setWA={setWA} clickClose={clickClose}/>
            <div id="container" style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 1, overflowY: (canScroll ? 'auto' : 'hidden'), overflowX: 'hidden' }}>
                {children}
                {
                    withFooter && (
                        <Footer wa={wa} fixedFooter={fixedFooter} ctx={ctx}/>
                    )
                }
            </div>
        </Fragment>
    );
};

export default WebLayout;