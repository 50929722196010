import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

const childFactoryCreator = (props) => child => React.cloneElement(child, props);

const Transition = ({ transition = '', duration = 0, pageKey, bgColor, children }) => {
    return (
        <TransitionGroup
            childFactory={childFactoryCreator({ classNames: transition, timeout: duration })}
        >
            <CSSTransition key={pageKey}>
                <div>
                    <div className="transition-bg" style={{
                        backgroundColor: bgColor ? bgColor : '#000'
                    }}></div>
                    {children}
                </div>
            </CSSTransition>
        </TransitionGroup>
    );
};

export default Transition;
    

export { default as right } from './lib/right';
export { default as bottom } from './lib/bottom';