import { useEffect, useState, useRef } from 'react';
import {
    Row,
    Col,
    message,
} from 'antd';
import Slider from "react-slick";
// import { Helmet } from "react-helmet";
import { apiGetAbout } from '../../api/about';
import WebLayout from '../../components/layout/webLayout';
import { useWindowSize } from '../../utils/useWindowSize';
import { errorHandling } from '../../utils/errorHandling';
import { RequestAPI } from '../../utils/requestApi';

const About = (props) => {
    const [getData, setGetData] = useState(true);
    const [colorIcon, setColorIcon] = useState(false);
    const [silderIndex, setSliderIndex] = useState(0);
    const [dotsDirection, setDotsDirection] = useState('');
    const [aboutData, setAboutData] = useState({});
    const [aboutImage, setAboutImage] = useState([]);
    const [aboutClients, setAboutClients] = useState([]);
    const [aboutTesti, setAboutTesti] = useState([]);
    const [seoData, setSEOData] = useState({
        seo_title: '',
        seo_description: '',
    });
    const slider = useRef(null);
    const size = useWindowSize();
    const {
        // history,
        seo,
    } = props;

    // const slide = (y) => {
    //     if (slider.current) {
    //         y > 0 ? (
    //             slider.current.slickPrev()
    //         ) : (
    //             slider.current.slickNext()
    //         )
    //     }
    // };

    const fetchAboutData = async () => {
        try {
            const response = await RequestAPI(apiGetAbout());
            if (response.status === 200 || response.status === 201) {
                const resData = response.data.data;
                let newSEOData = Object.assign({}, seoData);
                newSEOData.seo_title = seo.seo_title;
                newSEOData.seo_description = seo.seo_desc;

                if (resData.about_images.length > 0) {
                    let imageData = [];
                    resData.about_images.map((item) => {
                        if (item.status === "Active") {
                            imageData.push(item);
                        }
                        return imageData;
                    });
                    setAboutImage(imageData);
                }

                if (resData.about_client.length > 0) {
                    let clientData = [];
                    resData.about_client.map((item) => {
                        if (item.status === "Active") {
                            clientData.push(item);
                        }
                        return clientData;
                    });
                    setAboutClients(clientData);
                }

                if (resData.about_satisfied_client.length > 0) {
                    let clientTestiData = [];
                    resData.about_satisfied_client.map((item) => {
                        if (item.status === "Active") {
                            clientTestiData.push(item);
                        }
                        return clientTestiData;
                    });
                    setAboutTesti(clientTestiData);
                }

                setAboutData(resData.about);
                setSEOData(newSEOData);
            } else {
                const errMsg = response.error.message;
                message.error(errMsg);
            }
        } catch (error) {
            const errMsg = errorHandling(error);
            if (errMsg) {
                message.error(errMsg);
            }
        }
    };

    useEffect(() => {
        // const elem = document.getElementById('scroll-wrapper');
        // let mousewheelevt = (/Firefox/i.test(navigator.userAgent))? "DOMMouseScroll" : "mousewheel";
        // if (history.location.pathname === "/about") {
        //     const elemCarousel = document.getElementById('scroll-carousel');

        //     elemCarousel.addEventListener(mousewheelevt, (e) => {
        //         let delta = e.detail? e.detail*(-50) : e.wheelDelta;
        //         const scrollPosition = elem.scrollLeft -= delta;
        //         const fullWidth = elem.clientWidth;
        //         const fullScroll = elem.scrollWidth;
        //         if (scrollPosition > 150 && (fullScroll - scrollPosition > fullWidth)) {
        //             slide(delta);
        //             e.preventDefault();
        //         }
        //     });
        // }

        // elem.addEventListener(mousewheelevt, (e) => {
        //     let delta = e.detail? e.detail*(-25) : e.wheelDelta / 3;
        //     const scrollPosition = elem.scrollLeft -= delta;
        //     const fullWidth = elem.clientWidth;
        //     const fullScroll = elem.scrollWidth;
        //     if (scrollPosition > 150 && (fullScroll - scrollPosition > fullWidth)) {
        //         elem.scrollLeft -= delta;
        //         e.preventDefault();
        //     }
        // })

        const elmContainer = document.getElementById('container');
        elmContainer.addEventListener('scroll', (e) => {
            const elmDetail = document.getElementById('about-headline');
            const screenPosition = elmDetail.getBoundingClientRect();
            if ((screenPosition.bottom - 60) <= 0) {
                setColorIcon(true);
            } else {
                setColorIcon(false);
            }
        });
        const token = localStorage.getItem('token');

        if (getData && token) {
            fetchAboutData();
            setGetData(false);
        }
    // eslint-disable-next-line
    }, [getData]);

    let CarouselHeadlineSettings = {
        speed: 15000,
		autoplay: true,
		autoplaySpeed: 0,
		cssEase: 'linear',
        infinite: true,
        swipeToSlide: false,
        centerMode: false,
        focusOnSelect: false,
        variableWidth: true,
    };

    let CarouselSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        slidesToShow: (size.width <= 540 || aboutTesti.length < 2) ? 1 : 2,
        slidesToScroll: (size.width <= 540 || aboutTesti.length < 2) ? 1 : 2,
        beforeChange: (oldIndex, newIndex) => {
            setSliderIndex(newIndex);
            if (oldIndex > newIndex) {
                setDotsDirection('right');
            } else {
                setDotsDirection('left');
            }

            setTimeout(() => {
                setDotsDirection('');
            }, 300);
        },
        appendDots: (dots) => {
            return (
                <div>
                    <ul style={{ position: 'relative', padding: 0 }}>
                        <div 
                            className={"slick-worm " + (dotsDirection ? "slick-worm--active-go"+dotsDirection+" " : "")} 
                            style={{ left: (silderIndex * (size.width <= 540 ? 16 : 8)) + 'px' }} />
                        {
                            dots.map((item, index) => {
                                return (
                                    <li className={item.props.className} key={index}>
                                        {item.props.children}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            );
        }
    };

    const changeImage = (e, img) => {
        if (img) {
            e.target.src = img;
        }
    };

    return (
        <WebLayout bgWhite={colorIcon} canScroll={true} colorIcon={colorIcon} withFooter={true} ctx={props}>
            {/* <Helmet>
                <title>{seoData.seo_title}</title>
                <meta name="description" content={seoData.seo_description} />
                <meta property='og:title' content={seoData.seo_title}/>
                <meta property='og:description' content={seoData.seo_description}/>
            </Helmet> */}
            <div id="about-headline" className="xg__fullcontainer">
                <div className="about__overlay"/>
                <div className="about__headline-content xg__container-padding">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={20} xxl={18}>
                            <div className="about__mini-title">
                                {aboutData.sub_title}
                            </div>
                            <div className="about__title about__title--headline">
                                {aboutData.title}
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="about__headline-slides">
                    <Slider {...CarouselHeadlineSettings}>
                        {
                            aboutImage.map((item, index) => {
                                return (
                                    <div key={index} className="about__scroll-image about__scroll-image--headline about__scroll-image--vertical-middle">
                                        <img src={item.image} alt={"about-" + index} />
                                        {/* <div 
                                            className={"lists__content-thumbnail about__image"}
                                            style={{ backgroundImage: `url(${item.image})` }}
                                        /> */}
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
            {/* <div className="xg__container">
                <Row>
                    <Col xs={22} sm={22} md={20} lg={16} xl={14} xxl={12}>
                        <div className="about__mini-title">
                            {aboutData.sub_title}
                        </div>
                        <div className="about__title">
                            {aboutData.title}
                        </div>
                    </Col>
                </Row>
            </div>
            <div id="scroll-wrapper" className="lists__wrapper">
                {
                    aboutImage.map((item, index) => {
                        return (
                            <div key={index} className="about__scroll-image about__scroll-image--vertical-middle">
                                <div 
                                    className={"lists__content-thumbnail about__image"}
                                    style={{ backgroundImage: `url(${item.image})` }}
                                />
                            </div>
                        )
                    })
                }
            </div>
            <div className="xg__container about__desc">
                <Row>
                    <Col offset={14} span={8} xs={{ offset: 0, span: 24 }} sm={{ offset: 0, span: 24 }} md={{ offset: 10, span: 12 }} lg={{ offset: 14, span: 10 }} xl={{ offset: 14, span: 8 }}>
                        <p>
                            {aboutData.description}
                        </p>
                    </Col>
                </Row>
            </div> */}
            <div className="xg__container about__brands">
                <Row>
                    <Col xs={24} sm={24} md={16} lg={16} xl={12}>
                        <p>
                            {aboutData.description}
                        </p>
                    </Col>
                </Row>
            </div>
            <div className="xg__container about__brands">
                <Row>
                    <Col xs={24} sm={24} md={16} lg={16} xl={12}>
                        <div className="about__title">
                            {aboutData.brands_title}
                        </div>
                        <p>
                            {aboutData.brands_description}
                        </p>
                    </Col>
                </Row>
                <Row>
                    {
                        aboutClients.map((item, index) => {
                            return (
                                <Col className="about__img-list" key={index} xs={8} sm={8} md={8} lg={4}>
                                    <img 
                                        src={item.logo} 
                                        alt={'brand-list-'+item.name}
                                        onMouseEnter={(e) => changeImage(e, item.logo_hover)}
                                        onMouseLeave={(e) => changeImage(e, item.logo)}    
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
            <div className="xg__container">
                <Row>
                    <Col span={12} xs={24} sm={24} md={20}>
                        <div className="about__title">
                            {aboutData.testimonial_title}
                        </div>
                    </Col>
                </Row>
                <div id="scroll-carousel">
                    <Slider ref={slider} className={"home__carousel-wrapper home__carousel-wrapper--dots-purple about__carousel"} {...CarouselSettings}>
                        {
                            aboutTesti.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <Row align="top">
                                            <Col className="about__img-list" span={8}>
                                                <img src={item.xg_about_client.logo} alt={"about-img-"+item.xg_about_client.name} />
                                            </Col>
                                            <Col className="about__text-list" span={16} xs={24} sm={24} md={16}>
                                                <p className="about__client-desc">
                                                    {item.description}
                                                </p>
                                                <div className="about__client-name">
                                                    {item.name}
                                                </div>
                                                <p style={{ margin: 0 }}>
                                                    {item.position}
                                                </p>
                                                <p style={{ margin: 0 }}>
                                                    {item.xg_about_client.name}
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
        </WebLayout>
    );
};

export default About;