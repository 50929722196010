const getBaseUrl = process.env.REACT_APP_API_URL;

export const apiGetSocialMedia = () => ({
    method: 'POST',
    url: `${getBaseUrl}/social-media/get`,
    isAuthorize: true,
});

export const apiGetGeneralSetting = () => ({
    method: 'POST',
    url: `${getBaseUrl}/general-setting/get`,
    isAuthorize: true,
});